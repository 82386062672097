import './App.css';

import React, { useState, useEffect } from "react";


import axios from 'axios';

import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Figure from 'react-bootstrap/Figure';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Modal from 'react-bootstrap/Modal';

import Dropdown  from 'react-bootstrap/Dropdown';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import ring_shg from './assets/images/freqcomb_shg.jpg'
import chaotic_comb from './assets/images/comb.jpg'
import soliton_comb from './assets/images/comb.png'
import pic_image from './assets/images/pic.jpg'
import ff_pcbv1p1 from './assets/images/ff_pcbv1-1.PNG'

import { Chart } from "react-google-charts";
import { ButtonGroup, Col, Row } from 'react-bootstrap';

import { KoFiButton } from "react-kofi";
import "react-kofi/dist/styles.css";


import { Helmet } from 'react-helmet';


function App() {
  return (
    <Router>
      <title>Tristan's Personal Site</title>
      <main>
        <div className="App">
          <NavBarHeading/>
        </div>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/research" element={<ResearchPage/>} />
          <Route path='/personal-projects' element={<PersonalProjectsPage/>} />
          <Route path='/contact-me' element={<ContactMePage/>} />
          <Route path='/faradayfightstick' element={<FaradayFightstickProject/>} />
        </Routes>
      </main>
    </Router>
  );
}


function NavBarHeading() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary" >
      <Container fluid>
        <Navbar.Brand href="https://tristanmelton.com/">Tristan Melton</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="https://tristanmelton.com/research">Research</Nav.Link>
            <NavDropdown title="Personal Projects" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://tekkenratings.tristanmelton.com">Tekken 8 Tournament Ratings</NavDropdown.Item>
              <NavDropdown.Item href="https://sfratings.tristanmelton.com">Street Fighter 6 Tournament Ratings</NavDropdown.Item>
              <NavDropdown.Item href="https://tristanmelton.com/faradayfightstick">Faraday Fightstick</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="https://tristanmelton.com/contact-me">Contact Me</Nav.Link>
            <NavDropdown title="Wikis" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://tristanmelton.com/dokuwiki/doku.php?id=start">Fighting Game Wiki</NavDropdown.Item>
              <NavDropdown.Item href="https://tristanmelton.com/wong_lab/doku.php?id=start">Frequency Comb Subgroup Wiki</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function HomePage() {
  return (
    <>
      <head>
        <link rel="canonical" href="https://tristanmelton.com" />
      </head>
      <h1 style={{textAlign:'center'}}>Hi, my name is Tristan Melton.</h1>
      <div className="HomePageContent" style={{flex:1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width:'100rem', margin: 'auto'}}> 
        <div className="HomePage">
          <p style={{fontSize: '1.25rem'}}>
            I am a PhD candidate at in the Mesoscopic Optics and Quantum Electronics Laboratory at UCLA, under the direction of Professor Chee Wei Wong. My research interests
            surround photonic devices, specifically the development of photonic integrated circuit (PIC) chips for metrology in CMOS-compatible platforms. Most of my research 
            work thus far revolves around the application of Kerr frequency microcombs in these systems and methods of generation and stabilization.  My work can be found on the
            research tab of this site!
          </p>
        </div>
        <div className="HomePageFigures" style={{width:'60%'}}>
          <Figure>
            <Figure.Image
              alt="Second harmonic generation in a microresonator"
              src={ring_shg}
            />
            <Figure.Caption style={{textAlign: 'center'}}>
              Second harmonic generation from 1064-nm pump laser in a high quality factor microresonator.
            </Figure.Caption>
          </Figure>
          <Figure>
            <Figure.Image
              alt="Chaotic frequency microcomb"
              src={chaotic_comb}
            />
            <Figure.Caption style={{textAlign: 'center'}}>
              Chaotic frequency comb generated from a microresonator. In this instance, the comb is chaotic in the sense that fluctuations related to the mathematical concept of 
              chaos are present in the frequency and amplitude of each comb line, and no coherent pulse is formed.
            </Figure.Caption>
          </Figure>
        </div>
      </div>
    </>
  );
}

function ResearchPage() {
  return (
    <>
    <head>
    <head>
        <link rel="canonical" href="https://tristanmelton.com/research" />
      </head>
    </head>
      <div className="ResearchHeader" style={{textAlign:'center'}} >
        <h1>Research Work</h1>
        <p>
          Below you can find my research work, organized into groupings by topic. My Google Scholar page can be found <a href="https://scholar.google.com/citations?user=UTzpOhoAAAAJ&hl=en&oi=ao">here.</a>
        </p>
      </div>
      <ResearchCards/>    
      <ResearchCitations/>
    </>

  )
}
function ResearchCards() {
  return (
      <div className="ResearchCards" style={{flex:1, flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
        <Card style={{ maxWidth: '18rem', flex:1 }}>
          <Card.Img variant="top" src={soliton_comb} />
            <Card.Body>
              <Card.Title>Kerr Frequency Combs</Card.Title>
              <Card.Text>
                Research towards developing and designing Kerr frequency microcombs in Silicon Nitride. 
              </Card.Text>
            </Card.Body>
        </Card>

        <Card style={{ maxWidth: '18rem', flex:1 }}>
          <Card.Img variant="top" src={pic_image} />
            <Card.Body>
              <Card.Title>Photonic Integrated Circuits (PIC) Design</Card.Title>
              <Card.Text>
                Photonic integrated circuit design research and chip development.
              </Card.Text>
            </Card.Body>
          </Card>
      </div>
  );
}
function ResearchCitations() {
  return (
    <div className="ResearchAccordion" style={{flex:1, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Accordion defaultActiveKey="0" style={{ minwidth: '100rem',maxWidth: '100rem', flex:1}}>
        <Accordion.Item eventKey="0" style={{minWidth: '100rem', flex: 1}}>
          <Accordion.Header>Frequency Combs</Accordion.Header>
          <Accordion.Body>
            <h2>Accepted Journal Manuscripts</h2>
            - Alwaleed Aldhafeeri, Hsiao-Hsuan Chin, Tristan Melton, Dong IL Lee, Allen Chu, Wenting Wang, Mingbin Yu, Patrick Guo-Qiang Lo, Dim-Lee Kwong, and Chee Wei Wong, "Low phase noise K-band signal generation using polarization diverse single-soliton integrated microcombs," Photon. Res. 12, 1175-1185 (2024) <br/>
            - T. Melton, J. F. McMillan, J. Yang, W. Wang, Y. Lai, M. Gerber, M. Rodriguez, J. P. Hubschman, K. Nouri-Mahdavi, and C. W. Wong, Imaging optical coherence tomography and noise characterization based on 1-µm microresonator frequency combs, under review (2024). <br/>
            - W. Wang, H. Zhou, X. Jiang, T. Melton, A. K. Vinod, M. Yu, G.-Q. Lo, D.-L. Kwong, and C. W. Wong, Polarization-diverse soliton transitions and deterministic switching dynamics in strongly-coupled and self-stabilized microresonator frequency combs, arXiv 2303.03687 (2023). <br/>
            <h2>Accepted Conference Talks</h2>
            - T. Melton, H. Liu, W. Wang, M. Yu, D.-L. Kwong, and C. W. Wong, Observations of dual-dispersive soliton microcombs with deterministic azimuthal angle control, CLEO 2023, 1-2 (2023).<br/>
            - T. Melton, J. McMillan, W. Wang, Y. Lai, M. Gerber, M. Rodriguez, K. Nouri-Mahdavi, J. P. Hubschman, and C. W. Wong, Laser frequency microcombs for discrete-wavelength optical coherence tomography, CLEO 2023, AM3Q.1 (2023). <br/>
            - W. Wang, H. Liu, T. Melton, J. Yang, A. K. Vinod, J. Lim, Y.-S. Jang, H. Zhou, M. Yu, D.-L. Kwong, P. DeVore, J. Chou, and C. W. Wong, Sampling timing jitter in dispersion-managed frequency microcombs via a fiber interferometer, CLEO 2022, Stu1C.4 (2022). <br/>
            - T. Melton, J. McMillan, W. Wang, Y. Lai, M. Gerber, M. Rodriguez, K. Nouri-Mahdavi, J.P. Hubschman, and C. W. Wong, High-resolution millimeter-depth optical coherence tomography with 1-um 100-GHz chip-scale laser frequency microcombs, CLEO 2022 Conference <br/>
            - W. Romaszkan, T. Li, T. Melton, S. Pamarti and P. Gupta, ACOUSTIC: Accelerating convolutional neural networks through or-unipolar skipped stochastic computing, 2020 Design, Automation & Test in Europe Conference & Exhibition (DATE), Grenoble, France, 768-773 (2020). <br/>
            - W. Romaszkan, T. Li, T. Melton, S. Pamarti and P. Gupta, Machine learning at the edge using spintronic stochastic computing, GOMACTech-20 – Presentation.
          
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" style={{minWidth: '100rem', flex: 1}}>
          <Accordion.Header>PIC Design</Accordion.Header>
          <Accordion.Body>
          <h2>Accepted Journal Manuscripts</h2>
          - T. Melton, P. T. S. DeVore, J. McMillan, J. Chan, A. Calonico-Soto, K. M. Beck, C. W. Wong, J. T. Chou, and A. Gowda, Scalable stable comb-to-tone integrated RF photonic drive for superconducting qubits, Opt. Express 32, 18761-18770 (2024). <br/>
          <h2>Accepted Conference Talks</h2>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

    </div>
  );
}
function FaradayFightstickProject() {
  return (
    <>
    <head>
    <head>
        <link rel="canonical" href="https://tristanmelton.com/faradayfightstick" />
      </head>
    </head>
      <div className="FFHeader" style={{textAlign:'center'}} >
        <h1>Faraday Fightstick</h1>
        <p>
          <a href="https://github.com/tristanmelton/FaradayFightstick">Faraday Fightstick </a> is my take on a Hall Effect-based leverless controller. 
        </p>
        <p>
          The controller is comprised of two main control circuits: an analog-to-digital-to-onehot signal processor and the controller interpreter. 
          For version 1.0 and 1.1, I am using a Raspberry Pi Pico running GP2040-CE.
          The ADCO utilizes an Atmega32U4 microcontroller and two ADC108S022 which sample the Hall Effect sensors (DRV5053VAQDBZR).
        </p>
        <Figure>
            <Figure.Image
              width={700}
              height={300}
              alt="Faraday Fightstick PCB v1.1"
              src={ff_pcbv1p1}
            />
            <Figure.Caption style={{textAlign: 'center'}}>
              Faraday Fightstick PCB, version 1.1
            </Figure.Caption>
          </Figure>

        <p>
          The project is free and open-source - my only request is to cite this original work and give credit. 
        </p>
      </div>
    </>
  )
}
function PersonalProjectsPage() {
  let [gitProjs, setGitProjs] = useState([{name:'Loading', language: '', description: '', html_url: ''}, {name:'Loading', language: '', description: '', html_url: ''}]);
  useEffect(() => {
    axios.get('https://api.github.com/users/tristanmelton/repos?sort=created').then((responses) => {
        const repos = responses.data.map(({ name, language, html_url, created_at, description }) => {
          return { name, language, html_url, created_at, description };
        });    
        setGitProjs(repos);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <head>
        <link rel="canonical" href="https://tristanmelton.com/personal-projects" />
      </head>
      <div style={{flex:1, flexDirection: 'column', display:'flex', alignContent:'center', justifyContent:'center', textAlign:'center'}}>
        <h1>Personal Projects</h1>
      </div>
      <div style={{flex:1, flexDirection: 'row', display:'flex', alignContent:'center'}} >
        <Image src='https://avatars.githubusercontent.com/u/30941629?v=4' roundedCircle fluid style={{height: '15rem'}}/>
        <p style={{flex:1, alignContent:'center', fontSize: '2rem'}}>Below are some of my projects I've been working on!</p>
      </div>
      <div style={{flex:1, flexDirection: 'row', display:'flex', alignContent:'center', justifyContent:'center'}}><h2>Programming</h2></div>
      <div className="PersonalProjects" style={{flex:1, flexDirection: 'column', display: 'flex', justifyContent: 'center'}}>
        <Card style={{flex:1, textAlign:'center'}}>
          <Card.Body>
            <Card.Title>{gitProjs[0].name}</Card.Title>
            <Card.Subtitle>{gitProjs[0].language}</Card.Subtitle>
            <Card.Text>{gitProjs[0].description}</Card.Text>
          </Card.Body>
          <Card.Body>
            <Card.Link href={gitProjs[0].html_url}>{gitProjs[0].html_url}</Card.Link>
          </Card.Body>
        </Card>
        <Card style={{flex:1, textAlign:'center'}}>
          <Card.Body>
            <Card.Title>{gitProjs[1].name}</Card.Title>
            <Card.Subtitle>{gitProjs[1].language}</Card.Subtitle>
            <Card.Text>{gitProjs[1].description}</Card.Text>
          </Card.Body>
          <Card.Body>
            <Card.Link href={gitProjs[1].html_url}>{gitProjs[1].html_url}</Card.Link>
          </Card.Body>
        </Card>
        <div style={{flex:1, flexDirection: 'row', display:'flex', alignContent:'center', justifyContent:'center'}}><h2>Electronics</h2></div>
        <div className="PersonalProjects_Electronics" style={{flex:1, flexDirection: 'column', display: 'flex', justifyContent: 'center'}}>
        <Card style={{flex:1, textAlign:'center'}}>
          <Card.Body>
            <Card.Title>{"Custom PCB for Keyboard and Keypad"}</Card.Title>
            <Card.Subtitle>{"Put Image(s) Here"}</Card.Subtitle>
            <Card.Text>{"Put Description Here"}</Card.Text>
          </Card.Body>
        </Card>
        </div>
      </div>
    </>
  );
}
function ContactMePage() {
  return (
    <>
      <head>
        <link rel="canonical" href="https://tristanmelton.com/contact-me" />
      </head>
      <div style={{flex:1, flexDirection: 'column', display:'flex', alignContent:'center', justifyContent:'center', textAlign:'center'}}>
        <h1>Contact Information</h1>
        <p>Feel free to reach out to me regarding any of my prior, current, or future research plans or projects! I am always interested in hearing about new or interesting science or ideas. <br/>
        </p>
        <p>Email (for academic inquiries): tristanmelton@engineering.ucla.edu</p>
        <p>Email (for personal and all other inquiries): tristanmelton@proton.me</p>
      </div>
    </>
  );
}

export default App;
